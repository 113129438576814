import React from "react";
import "./Main.css";
import CurrencyForm from "./CurrencyForm";
import Header from "../Header";
import BannerSection from "../BannerSection";
import Footer from "../Footer";

const Main = () => {
  return (
    <>
      <Header />
      <BannerSection pageHeader="Currency Converter" />
      <div className="main-head">
        <div className="currency-converter">
          <h2 className="converter-title">Currency Converter</h2>
          <CurrencyForm />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Main;
