import React from "react";
import SectionHeader from "../SectionHeader";
import kothari from "../assets/Kothari.png";
import prudence from "../assets/SchoolsColPic/prudence_logo.png";
import heritage from "../assets/SchoolsColPic/heritage_logo.png";
import presidium from "../assets/SchoolsColPic/presidium_logo.jpg";
import lotus from "../assets/SchoolsColPic/lotus_logo.jpg";

const SchoolCollaborations = () => {
  return (
    <>
      <SectionHeader firstTxt="Schools" lastTxt="We have Worked with" />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-2 gate" style={{ paddingTop: "20px" }}>
            <img
              src="images/Collaboration_pics/GE-Logo2.png"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-md-3 sps" style={{ paddingLeft: "100px" }}>
            <img
              src="images/Collaboration_pics/sps.jpeg"
              style={{ width: "35%" }}
            />
          </div>
          <div className="col-md-2 sajs" style={{ paddingTop: "10px" }}>
            <img
              src="images/Collaboration_pics/SAJS.jpg"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-md-3 bs" style={{ paddingLeft: "100px" }}>
            <img
              src="images/Collaboration_pics/bright_scholar.png"
              style={{ width: "50%" }}
            />
          </div>
          <div className="col-md-2 hrm">
            <img
              src="images/Collaboration_pics/HRM-Logo.png"
              style={{ width: "80%" }}
            />
          </div>
          <div
            className="col-md-3 kot mt-4"
            style={{ paddingLeft: "50px", width: "17%" }}
          >
            <img src={kothari} style={{ width: "50%" }} />
          </div>
          <div className="col-md-2 mbsColl mt-4" style={{ width: "25%" }}>
            <img
              src="images/Collaboration_pics/mbs_college.png"
              style={{ width: "80%" }}
            />
          </div>
          <div className="col-md-2 xav mt-4" style={{ paddingTop: "15px" }}>
            <img
              src="images/Collaboration_pics/XavLogo.png"
              style={{ width: "100%" }}
            />
          </div>
          <div
            className="col-md-2 ran mt-4"
            style={{ paddingLeft: "100px", width: "25%" }}
          >
            <img
              src="images/Collaboration_pics/school-logo.png"
              style={{ width: "45%" }}
            />
          </div>
          <div className="col-md-3 mbsSch mt-4" style={{ width: "16%" }}>
            <img
              src="images/Collaboration_pics/mbs_school.png"
              style={{ width: "100%" }}
            />
          </div>
          <div
            className="col-md-3 lys mt-4"
            style={{ width: "17%", paddingTop: "10px", paddingLeft: "30px" }}
          >
            <img
              src="images/Collaboration_pics/lycee suger logo.jpg"
              style={{ width: "80%" }}
            />
          </div>
          <div className="col-md-3 hot mt-4" style={{ paddingLeft: "80px" }}>
            <img
              src="images/Collaboration_pics/hope_town.png"
              style={{ width: "60%" }}
            />
          </div>
          <div
            className="col-md-3 tuli mt-4"
            style={{ paddingLeft: "55px", paddingTop: "10px", width: "17%" }}
          >
            <img
              src="images/Collaboration_pics/Tuli.jpg"
              style={{ width: "60%" }}
            />
          </div>
          <div
            className="col-md-3 sparsh mt-4"
            style={{ paddingLeft: "69px", paddingTop: "39px", width: "25%" }}
          >
            <img
              src="images/Collaboration_pics/sparsh_logo.png"
              style={{ width: "75%" }}
            />
          </div>
          <div
            className="col-md prud mt-4"
            style={{ paddingTop: "39px", width: "28%" }}
          >
            <img src={prudence} style={{ width: "100%" }} />
          </div>
          <div
            className="col-md-3 heri mt-4"
            style={{ paddingLeft: "32px", paddingTop: "52px", width: "20%" }}
          >
            <img src={heritage} style={{ width: "90%" }} />
          </div>
          <div
            className="col-md-3 presi mt-4"
            style={{ paddingLeft: "30px", width: "20%" }}
          >
            <img src={presidium} style={{ width: "60%" }} />
          </div>
          <div
            className="col-md-3 lotus mt-4"
            style={{ paddingLeft: "28px", paddingTop: "34px", width: "20%" }}
          >
            <img src={lotus} style={{ width: "95%" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolCollaborations;
