import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { Routes, Route } from "react-router-dom";
import Homepage from "./Homepage";
import About from "./About";
import Contact from "./Contact";
import Founder from "./Founder";
import News from "./News";
import CompanyProfile from "./CompanyProfile";
import Gallery from "./Gallery";
import Blogs from "./Blogs";
import ItineraryParis from "./ItineraryParis";
import RegistrationForm from "./RegistrationForm";
import Parisedutour from "./Parisedutour";
import Termsandconditions from "./Termsandconditions";
import Privacypolicy from "./Privacypolicy";
import StudentDashboard from "./user/StudentDashboard";
import QuizDay from "./user/QuizDay";
import ScrollToTop from "./ScrollToTop";
import ParisBlog1 from "./Homepage/Blogs/IntBlog/ParisBlog1";
import ParisBlog2 from "./Homepage/Blogs/IntBlog/ParisBlog2";
import Blog from "./Homepage/Blogs/Blog";
import Paris from "./Homepage/Excursions/IntExcursion/Paris";
import Dubai from "./Homepage/Excursions/IntExcursion/Dubai";
import Singapore from "./Homepage/Excursions/IntExcursion/Singapore";
import Vietnam from "./Homepage/Excursions/IntExcursion/Vietnam";
import Dharamshala from "./Homepage/Blogs/DomBlog/Dharamshala";
import Goa from "./Homepage/Blogs/DomBlog/Goa";
import DubaiBlog from "./Homepage/Blogs/IntBlog/Dubai";
import Ahmedabad from "./Homepage/Blogs/DomBlog/Ahmedabad";
import BanaHills from "./Homepage/Blogs/IntBlog/BanaHills";
import VitenamBlog from "./Homepage/Blogs/IntBlog/Vietnam";
import HalongBay from "./Homepage/Blogs/IntBlog/HalongBay";
import Shimla from "./Homepage/Blogs/DomBlog/Shimla";
import Manali from "./Homepage/Blogs/DomBlog/Manali";
import Main from "./Currency/Main";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/home/" element={<Homepage />} />
        <Route exact path="/about/" element={<About />} />
        <Route exact path="/contact/" element={<Contact />} />
        <Route exact path="/founder/" element={<Founder />} />
        <Route exact path="/news/" element={<News />} />
        <Route exact path="/companyProfile/" element={<CompanyProfile />} />
        <Route exact path="/gallery/" element={<Gallery />} />
        <Route exact path="/blogs/" element={<Blogs />} />
        <Route exact path="/itinerary/:place" element={<ItineraryParis />} />
        <Route exact path="/registrationform/" element={<RegistrationForm />} />
        <Route exact path="/parisedutour/" element={<Parisedutour />} />
        <Route
          exact
          path="/terms_and_conditions/"
          element={<Termsandconditions />}
        />
        <Route exact path="/privacy_policy/" element={<Privacypolicy />} />
        <Route
          exact
          path="/student_dashboard/"
          element={<StudentDashboard />}
        />
        <Route exact path="/quizday_1/" element={<QuizDay />} />
        <Route exact path="/blog_1/" element={<ParisBlog1 />} />
        <Route exact path="/blog_2/" element={<ParisBlog2 />} />
        <Route exact path="/blog/" element={<Blog />} />
        <Route exact path="/paris/" element={<Paris />} />
        <Route exact path="/dubai/" element={<Dubai />} />
        <Route exact path="/singapore/" element={<Singapore />} />
        <Route exact path="/vietnam/" element={<Vietnam />} />
        <Route exact path="/blog/dharamshala/" element={<Dharamshala />} />
        <Route exact path="/blog/goa/" element={<Goa />} />
        <Route exact path="/blog/dubai/" element={<DubaiBlog />} />
        <Route exact path="/blog/ahmedabad/" element={<Ahmedabad />} />
        <Route exact path="/blog/banahills/" element={<BanaHills />} />
        <Route exact path="/blog/vietnam/" element={<VitenamBlog />} />
        <Route exact path="/blog/halongbay/" element={<HalongBay />} />
        <Route exact path="/blog/shimla/" element={<Shimla />} />
        <Route exact path="/blog/manali/" element={<Manali />} />
        <Route exact path="/currency-converter/" element={<Main />} />
      </Routes>
    </>
  );
}

export default App;
