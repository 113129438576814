import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import BannerSection from "./BannerSection";
import HTMLFlipBook from "react-pageflip";
import Company_Profile from "./assets/Company_Profile.pdf";
import { AiOutlineDownload } from "react-icons/ai";

const CompanyProfile = () => {
  const pages = [
    {
      src: "images/pdfImages/Memories_Maker-3_page-0001.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0002.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0003.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0004.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0005.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0006.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0007.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0008.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0009.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0010.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0011.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0012.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0013.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0014.jpg",
    },
    {
      src: "images/pdfImages/Memories_Maker-3_page-0015.jpg",
    },
  ];
  return (
    <>
      <Header />
      <BannerSection pageHeader="COMPANY PROFILE" />
      <div className="blogContenT">
        <iframe
          src="https://memoriesmakeredu.com/Memories_Maker-3.pdf"
          name="exam_frame"
        ></iframe>
      </div>

      <div className="flipBook">
        <HTMLFlipBook width={300} height={550} size="stretch">
          {pages && pages.length > 0 ? (
            pages.map((page, index) => (
              <div key={index} className="pdfPage">
                <img
                  src={page.src}
                  style={{ width: "100%", height: "90%" }}
                  alt={`Page ${index}`}
                />
              </div>
            ))
          ) : (
            <p>Loading pages...</p>
          )}
        </HTMLFlipBook>
      </div>
      <div className="cta">
        <p>You can also Download the PDF of our Company Profile here</p>
        <a href={Company_Profile} download className="BTN">
          Download{" "}
          <span>
            <AiOutlineDownload />
          </span>
        </a>
      </div>
      <Footer />
    </>
  );
};

export default CompanyProfile;
